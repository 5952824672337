<script setup lang="ts">
import { useDisplay } from 'vuetify'

const THUMBNAIL_WIDTH = 1280
const THUMBNAIL_HEIGHT = 720
const RATIO = THUMBNAIL_WIDTH / THUMBNAIL_HEIGHT

const props = withDefaults(
  defineProps<{
    url?: string
    maxWidth?: number
    width?: string
    height?: string
    title?: string
    radius?: string
  }>(),
  {
    maxWidth: THUMBNAIL_WIDTH,
    title: 'YouTube Video Player',
    radius: '1em',
  },
)

const { width: vWidth, name, lgAndUp } = useDisplay()

const iWidth = ref(THUMBNAIL_WIDTH)
const iHeight = ref(THUMBNAIL_HEIGHT)

watch(
  vWidth,
  (v) => {
    if (v <= 400) {
      const s = 3.5
      iWidth.value = Math.round(THUMBNAIL_WIDTH / s)
      iHeight.value = Math.round(THUMBNAIL_HEIGHT / s)
      return
    }

    if (name.value === 'xs') {
      const s = 3
      iWidth.value = Math.round(THUMBNAIL_WIDTH / s)
      iHeight.value = Math.round(THUMBNAIL_HEIGHT / s)
      return
    }

    if (name.value === 'sm') {
      const s = 2
      iWidth.value = Math.round(THUMBNAIL_WIDTH / s)
      iHeight.value = Math.round(THUMBNAIL_HEIGHT / s)
      return
    }

    if (name.value === 'md') {
      const s = 1.5
      iWidth.value = Math.round(THUMBNAIL_WIDTH / s)
      iHeight.value = Math.round(THUMBNAIL_HEIGHT / s)
      return
    }

    if (lgAndUp.value === true) {
      if (props.maxWidth < THUMBNAIL_WIDTH) {
        const scale = props.maxWidth / THUMBNAIL_WIDTH
        iWidth.value = Math.round(props.maxWidth)
        iHeight.value = Math.round(scale * THUMBNAIL_HEIGHT)
      } else {
        iWidth.value = Math.round(THUMBNAIL_WIDTH)
        iHeight.value = Math.round(THUMBNAIL_HEIGHT)
      }
      return
    }
  },
  { immediate: true },
)

const _width = computed(() => {
  if (props.height) {
    return props.width
  }
  return iWidth.value
})

const _height = computed(() => {
  if (props.height) {
    return props.height
  }
  return iHeight.value
})
</script>

<template>
  <div class="container">
    <iframe
      :width="_width"
      :height="_height"
      :src="props.url"
      :title="props.title"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
      class="iframe-style"
    ></iframe>
  </div>
</template>

<style scoped>
.container {
  padding: 1em 0em;
  /* background-color: yellow; */
}

.iframe-style {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: v-bind(radius);
  /* background-color: green; */
}
</style>
